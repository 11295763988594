import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import { splitText } from "@utils"
import React, { useEffect, useRef } from "react"

const PrivacyPolicyPage = ({ data }) => {
  const termsRef = useRef(null)

  const { themeColor } = usePageSetup({ themeColor: "bg-pink-dark", displayGeneralForm: false })

  useEffect(() => {
    async function getPolicy() {
      const policyResponse = await fetch(
        "https://app.termageddon.com/api/policy/U2sxRk5rUjVkSGczU1U4dk0yYzlQUT09?h-align=left&table-style=accordion"
      )
      const policyContent = await policyResponse.text()

      termsRef.current.innerHTML = policyContent
    }

    getPolicy()
  }, [])

  return (
    <Layout>
      <Seo title="Privacy Policy" description="" />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "Privacy policy.",
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <main id="privacy-policy">
        <section className="secondary py-clamp-18-35">
          <div className="container grid-cols-12 lg:grid">
            <div className="col-span-9 col-start-2 xl:col-start-3">
              <div ref={termsRef} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default PrivacyPolicyPage
